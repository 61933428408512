import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // folder/Inbox
  {
    path: '',
    redirectTo: 'home/?:id',
    pathMatch: 'prefix'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'addsectiondev',
    loadChildren: () => import('./addsectiondev/addsectiondev.module').then( m => m.AddsectiondevPageModule)
  },
  {
    path: 'newpage',
    loadChildren: () => import('./newpage/newpage.module').then( m => m.NewpagePageModule)
  },
  {
    path: 'testsection',
    loadChildren: () => import('./testsection/testsection.module').then( m => m.TestsectionPageModule)
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'addsectionfview',
    loadChildren: () => import('./addsectionfview/addsectionfview.module').then( m => m.AddsectionfviewPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'reorderpage',
    loadChildren: () => import('./reorderpage/reorderpage.module').then( m => m.ReorderpagePageModule)
  },
  {
    path: 'hideshowpage',
    loadChildren: () => import('./hideshowpage/hideshowpage.module').then( m => m.HideshowpagePageModule)
  },
  {
    path: 'companyinfo',
    loadChildren: () => import('./companyinfo/companyinfo.module').then( m => m.CompanyinfoPageModule)
  },
  {
    path: 'announce',
    loadChildren: () => import('./announce/announce.module').then( m => m.AnnouncePageModule)
  },
  {
    path: 'renamepage',
    loadChildren: () => import('./renamepage/renamepage.module').then( m => m.RenamepagePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
