import { Injectable } from '@angular/core';
import { Headers, Response, Http} from '@angular/http';
import 'rxjs/add/operator/map';
import { ToastController } from '../../node_modules/@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  // _url = 'https://sapphireprofits.com/qorder2/api/';
  _url = 'https://qorder.co/qordernew/api/';

  admin_name='';admin_pwd='';
  themecolors=[];
  constructor(public _http:Http, public toast:ToastController) { }

  service_post(api, params) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this._url+api,params+"&"+Date.now(),{headers:headers})
    .map((response:Response) => response.json());
  }

  showtoast(msg) {
    this.toast.create({
      message: msg,
      duration:1000,
      position:'top'
    }).then(toast=>{
      toast.present();
    })
  }

  setcolor(){
    let api_i = 'gettheme.php';
    let params_s = '';
    this.service_post(api_i, params_s).subscribe(data  =>{
      console.log(data);
      if(data.length > 0){
        this.themecolors = data;
        document.documentElement.style.setProperty('--prim-clr', this.themecolors[0]['c_primary']);
        document.documentElement.style.setProperty('--sec-clr', this.themecolors[0]['c_secondary']);
        document.documentElement.style.setProperty('--teri-clr', this.themecolors[0]['c_territory']);
        document.documentElement.style.setProperty('--clr-ex1', this.themecolors[0]['clr_ex1']);
        document.documentElement.style.setProperty('--clr-ex2', this.themecolors[0]['clr_ex2']);
        document.documentElement.style.setProperty('--clr_ex3', this.themecolors[0]['clr_ex3']);
        document.documentElement.style.setProperty('--clr_ex4', this.themecolors[0]['clr_ex4']);
        document.documentElement.style.setProperty('--clr-ex5', this.themecolors[0]['clr_ex5']);
        document.documentElement.style.setProperty('--clr-ex6', this.themecolors[0]['clr_ex6']);
      }
    });

    
  }

  get_prim_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--prim-clr');
  }

  get_sec_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--sec-clr');
  }
  
  get_teri_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--teri-clr');
  }
  get_white_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--color-white');
  }
  get_black_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--color-black');
  }
  get_clrex1_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--clr-ex1');
  }
  get_clrex2_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--clr-ex2');
  }
  get_clrex3_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--clr-ex3');
  }
  get_clrex4_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--clr-ex4');
  }
  get_clrex5_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--clr-ex5');
  }
  get_clrex6_color(){
    return getComputedStyle(document.documentElement).getPropertyValue('--clr-ex6');
  }

}
