import { Component, OnInit } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { UserService } from './user.service';
import { Router } from '../../node_modules/@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'View Site',
      url: '/home/?:id',
      icon: 'paper-plane',
    },
    {
      title: 'Add New Page',
      url: '/newpage',
      icon: 'paper-plane'
    },
    {
      title: 'Rename Page',
      url: '/renamepage',
      icon: 'paper-plane'
    },
    {
      title: 'Reorder Page',
      url: '/reorderpage',
      icon: 'paper-plane'
    },
    {
      title: 'Hide/Show Page',
      url: '/hideshowpage',
      icon: 'paper-plane'
    },
    {
      title: 'Add new section',
      url: '/addsectiondev',
      icon: 'paper-plane'
    },
    {
      title: 'Manage Page',
      url: '/addsectionfview',
      icon: 'paper-plane'
    },
    {
      title: 'Add announcement',
      url: '/announce',
      icon: 'paper-plane'
    },
    {
      title: 'Settings',
      url: '/companyinfo',
      icon: 'paper-plane'
    },
    // {
    //   title: 'Inbox',
    //   url: '/folder/Inbox',
    //   icon: 'mail'
    // },
    // {
    //   title: 'Outbox',
    //   url: '/folder/Outbox',
    //   icon: 'paper-plane'
    // },
    // {
    //   title: 'Favorites',
    //   url: '/folder/Favorites',
    //   icon: 'heart'
    // },
    // {
    //   title: 'Archived',
    //   url: '/folder/Archived',
    //   icon: 'archive'
    // },
    // {
    //   title: 'Trash',
    //   url: '/folder/Trash',
    //   icon: 'trash'
    // },
    // {
    //   title: 'Spam',
    //   url: '/folder/Spam',
    //   icon: 'warning'
    // }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public user:UserService,
    public menuCtrl:MenuController,
    public router:Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.sections();
    this.menuCtrl.toggle();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    
  }

  ngOnInit() {
    //console.log(window.location.pathname);
    if(window.location.pathname == '/home/' || window.location.pathname == '/home') {
      this.router.navigateByUrl('home/#');
    }
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.user.setcolor();
  }

  sections()
  {
      let api = 'newselsection.php'; 
      this.user.service_post(api, '').subscribe(data=>{
        if(data.length !=0)
        {        
        
        var csscode='';
        for(let i = 0; i < data.length; i++)
        {
          if(data[i]['sec_css'] != '' && data[i]['sec_css'] != null && data[i]['sec_css'] != undefined)
          {
            if(i == 0)
            {
              csscode = '<style>'; 
            }
            csscode += data[i]['sec_css'] + ' ';
            //console.log(csscode); 
            if(data.length-1 == i)
            {
              csscode += '</style>';
              localStorage.setItem('seccss', csscode);
              
            }
          }
        }
        }
        else{
          
          localStorage.setItem('seccss', '<style></style>');
        }  
      },err=>{
        localStorage.setItem('seccss', '<style></style>');
      });
    
  }

}
